// Angular
import { Component, Input } from '@angular/core';
// Models
import { StatusType } from '../../models';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html'
})
export class StatusComponent {

  @Input()
  public type!: StatusType;

  @Input()
  public icon!: StatusType;

  @Input()
  public heading: string | undefined;

  @Input()
  public message: string | undefined;

  constructor() {
  }
}

// Angular
import { ApplicationRef, createComponent, Inject, Injectable, Injector, Type } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// Models
import { Overlay } from '../models';

@Injectable()
export class OverlayService {

  constructor(private applicationRef: ApplicationRef, @Inject(DOCUMENT) private document: Document) {
  }

  public create<T>(component: Type<T>): Overlay<T> {
    const overlay = new Overlay<T>();
    const environmentInjector = this.applicationRef.injector;
    const elementInjector = Injector.create({ providers: [{ provide: Overlay, useValue: overlay }] });
    const componentRef = createComponent(component, { environmentInjector, elementInjector });

    overlay.instance = componentRef.instance;

    overlay.onPresent.subscribe({
      next: () => {
        this.applicationRef.attachView(componentRef.hostView);
        this.document.body.appendChild(componentRef.location.nativeElement);
      }
    });

    overlay.onDismiss.subscribe({
      next: () => {
        this.applicationRef.detachView(componentRef.hostView);
        componentRef.destroy();
      }
    });

    return overlay;
  }
}

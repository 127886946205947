// Angular
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: 'input[emailValid][ngModel], input[emailValid][formControl], input[emailValid][formControlName]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: EmailValidDirective, multi: true }
  ]
})
export class EmailValidDirective implements Validator {

  constructor() {
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (!control || !control.value) {
      return null;
    }

    const value = control.value.toString();
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(value) ? null : { emailValid: true };
  }
}

// Angular
import { Component, Input } from '@angular/core';
// Models
import { DeviceActivityModel } from '@data/models';
import { Overlay } from '@libs/core/models';
// Services
import { DeviceService } from '@data/services/device.service';
import { NotificationService } from '@libs/core/services/notification.service';
// External
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-device-activity-verify-modal',
  templateUrl: './device-activity-verify-modal.component.html'
})
export class DeviceActivityVerifyModalComponent {

  @Input()
  public deviceActivity!: DeviceActivityModel;

  public loading: boolean;

  constructor(private overlay: Overlay<DeviceActivityVerifyModalComponent>, private deviceService: DeviceService, private notificationService: NotificationService, private translateService: TranslateService) {
    this.loading = false;
  }

  public confirmDeviceActivity(): void {
    this.loading = true;
    this.deviceService.confirmDeviceActivity(this.deviceActivity.id).pipe(
      finalize(() => {
        this.loading = false;
      })
    ).subscribe({
      next: deviceActivity => {
        this.overlay.dismiss(deviceActivity);
      },
      error: async () => {
        const notification = await this.translateService.get('notification.error.save').toPromise();
        this.notificationService.showDanger(notification);
      }
    });
  }

  public closeHandler(): void {
    this.overlay.dismiss();
  }
}

// Angular
import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[type="text"][replacePattern][ngModel], input[type="text"][replacePattern][formControl], input[type="text"][replacePattern][formControlName]'
})
export class ReplacePatternDirective {

  @Input()
  public replacePattern!: string;

  constructor(private ngControl: NgControl) {
  }

  @HostListener('input', ['$event.target.value'])
  public inputHandler(value: string): void {
    this.ngControl.control!.patchValue(value.replace(new RegExp(this.replacePattern, 'g'), ''));
  }
}

<app-modal [title]="multiple ? ('devices.select_devices' | translate) : ('devices.select_device' | translate)" (close)="closeHandler()">
  <form class="relative" action="" autocomplete="off">
    <input [(ngModel)]="query" class="form-control px-10 bg-transparent" name="query" [placeholder]="'devices.search' | translate" type="search" (ngModelChange)="searchHandler()">
    <button class="flex items-center justify-center absolute top-0 left-0 z-10 w-10 h-10 text-gray-600 pointer-events-none" tabindex="-1" aria-label="search" type="submit">
      <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M15.707,13.293L13,10.586c0.63-1.05,1-2.275,1-3.586c0-3.86-3.141-7-7-7S0,3.14,0,7s3.141,7,7,7 c1.312,0,2.536-0.369,3.586-1l2.707,2.707C13.488,15.902,13.744,16,14,16s0.512-0.098,0.707-0.293l1-1 C16.098,14.316,16.098,13.684,15.707,13.293z M7,12c-2.761,0-5-2.239-5-5s2.239-5,5-5s5,2.239,5,5S9.761,12,7,12z"></path></svg>
    </button>
    <button *ngIf="!!query" class="flex items-center justify-center absolute z-10 top-0 right-0 w-10 h-10 text-gray-600 transition-colors hover:text-gray-900 focus:outline-none" tabindex="-1" aria-label="reset" type="reset">
      <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M11.5,10.1l-1.4,1.4L8,9.4l-2.1,2.1l-1.4-1.4L6.6,8 L4.5,5.9l1.4-1.4L8,6.6l2.1-2.1l1.4,1.4L9.4,8L11.5,10.1z"></path></svg>
    </button>
  </form>
  <div class="mt-5 space-y-5">
    <div *ngIf="!!devices && !devices.length" class="p-5 text-gray-500 text-center">{{ 'devices.empty' | translate }}</div>
    <div *ngFor="let device of devices; index as index;" class="flex items-center relative">
      <div *ngIf="multiple" class="flex-shrink-0 mr-5">
        <input [ngModel]="getSelectedDevice(device.id)" [ngModelOptions]="{ standalone: true }" class="form-check" type="checkbox">
      </div>
      <div class="flex items-center justify-center flex-shrink-0 w-10 h-10 mr-3 text-gray-500 bg-gray-50 rounded-full">
        <svg class="w-6 h-6 stroke-2 stroke-current" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="11.5" x2="12" y2="22.5" stroke-linecap="butt"></line><polyline points="3 6.5 12 11.5 21 6.5" fill="none" stroke-linecap="butt"></polyline><polygon points="21 17.5 21 6.5 12 1.5 3 6.5 3 17.5 12 22.5 21 17.5" fill="none" stroke-linecap="butt"></polygon></svg>
      </div>
      <div class="flex-1">
        <p *ngIf="multiple" class="text-gray-900"><button *ngIf="multiple" class="block w-full text-left" type="button" (click)="selectDevice(device)"><span class="absolute inset-0 z-10"><span class="sr-only">Wybierz urządzenie</span></span><span>{{ device.name }}</span></button></p>
        <p *ngIf="!multiple" class="flex items-center">
          <span *ngIf="hasReservation(device) || hasReservationByMyself(device)" class="flex-shrink-0 mr-2" [ngClass]="{ 'text-red-600': hasReservation(device), 'text-green-600': hasReservationByMyself(device) }"><svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M12,6H10V4A2,2,0,0,0,6,4V6H4V4a4,4,0,0,1,8,0Z"></path><path d="M14,7H2A1,1,0,0,0,1,8v7a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V8A1,1,0,0,0,14,7ZM8,13a2,2,0,1,1,2-2A2,2,0,0,1,8,13Z"></path></svg></span>
          <span class="text-gray-900">{{ device.name }}</span>
          <span *ngIf="!!device.label" class="badge ml-2" [ngClass]="{ 'badge-green': device.label === 'active', 'badge-yellow': device.label === 'installation' || device.label === 'deinstallation' || device.label === 'repair' || device.label === 'in_repair', 'badge-red': device.label === 'reservation' }">{{ 'device.label.' + device.label | translate }}</span>
        </p>
        <p class="text-gray-500">S/N: {{ device.serialNumber }}</p>
        <p *ngIf="!!device.customer" class="text-gray-500">{{ 'devices.location' | translate }}: {{ device.customer.street }}, {{ device.customer.postCode }} {{ device.customer.city }}</p>
        <p class="text-gray-500">{{ 'device.owner.ownership_type' | translate }}: <span *ngIf="device.ownershipType === 'sale'">{{ 'device.owner.client' | translate }}</span><span *ngIf="device.ownershipType !== 'sale'">Baxter</span></p>
      </div>
      <div *ngIf="!multiple && !hasReservation(device)" class="flex-shrink-0 ml-2">
        <button class="button-icon text-gray-500" aria-label="select" type="button" (click)="selectDevice(device)">
          <span class="absolute inset-0 z-10"><span class="sr-only">{{ 'devices.select_device' | translate }}</span></span>
          <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><polygon points="4.9,15.7 3.4,14.3 9.7,8 3.4,1.7 4.9,0.3 12.6,8"></polygon></svg>
        </button>
      </div>
    </div>
  </div>
  <p *ngIf="loading" class="flex items-center justify-center p-5"><span class="flex-shrink-0 mr-2"><svg class="w-4 h-4 mr-2 fill-current animate-spin" xmlns="http://www.w3.org/2000/svg"><path d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM8,2a6,6,0,1,0,6,6A6.006,6.006,0,0,0,8,2Z" opacity="0.4"></path><path d="M16,8H14A6.006,6.006,0,0,0,8,2V0A8.009,8.009,0,0,1,16,8Z"></path></svg></span><span>{{ 'shared.loading' | translate }}…</span></p>
  <div *ngIf="!loading && pagination.pageCount > 1 && pagination.page !== pagination.pageCount" class="mt-5">
    <button class="button button-outline-blue" type="button" (click)="getDevices(pagination.page + 1)">{{ 'shared.more' | translate }}</button>
  </div>
  <div *ngIf="multiple" class="mt-5 -mb-5 -mx-5 py-3 px-5 text-right border-t border-gray-100">
    <button class="button button-solid-blue" [disabled]="!selectedDevices.length" type="button" (click)="addDevices()">{{ 'shared.add' | translate }}<span *ngIf="!!selectedDevices.length">&nbsp;({{ selectedDevices.length }})</span></button>
  </div>
</app-modal>

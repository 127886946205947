// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// Models
import { DashboardStatsModel } from '../models';
// External
import { Observable } from 'rxjs';

@Injectable()
export class DashboardService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getDashboardStats(businessUnits: Array<number> = []): Observable<DashboardStatsModel> {
    let params = new HttpParams();

    businessUnits.forEach(businessUnit => {
      params = params.append('businessUnits[]', businessUnit);
    });

    return this.httpClient.get<DashboardStatsModel>(`${this.apiUrl}/app-admin/v1/dashboard/stats`, { params });
  }
}

// Angular
import { Component, Input, OnInit } from '@angular/core';
// Models
import { DeviceModel, DevicesFilterModel, PaginationMetaModel } from '@data/models';
import { Overlay } from '@libs/core/models';
// Services
import { AccountService } from '@data/services/account.service';
import { DeviceService } from '@data/services/device.service';
// External
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-devices-modal',
  templateUrl: './devices-modal.component.html'
})
export class DevicesModalComponent implements OnInit {

  @Input()
  public filter!: DevicesFilterModel;

  @Input()
  public multiple: boolean;

  public query: string;

  public devices: Array<DeviceModel>;
  public selectedDevices: Array<DeviceModel>;
  public pagination: PaginationMetaModel;

  public loading: boolean;

  private searchDebounceTimeout: number | undefined;

  constructor(private overlay: Overlay<DevicesModalComponent>, private accountService: AccountService, private deviceService: DeviceService) {
    this.multiple = false;
    this.query = '';

    this.devices = [];
    this.selectedDevices = [];

    this.pagination = {
      page: 1,
      perPage: 1,
      count: 1,
      pageCount: 1
    };

    this.loading = false;
  }

  ngOnInit(): void {
    this.getDevices();
  }

  public hasReservation(device: DeviceModel): boolean {
    const { id } = this.accountService.getCurrentAccount()!;
    return device.reservation && device.reservationBy.id !== id;
  }

  public hasReservationByMyself(device: DeviceModel): boolean {
    const { id } = this.accountService.getCurrentAccount()!;
    return device.reservation && device.reservationBy.id === id;
  }

  public getDevices(page: number = 1): void {
    this.loading = true;
    this.deviceService.getDevices({ ...this.filter, page, q: this.query || '' }).pipe(
      finalize(() => {
        this.loading = false;
      })
    ).subscribe(({ data, meta }) => {
      this.devices = meta.page !== 1 ? [...this.devices, ...data] : data;
      this.pagination = meta;
    });
  }

  public searchHandler(): void {
    window.clearTimeout(this.searchDebounceTimeout);

    this.searchDebounceTimeout = window.setTimeout(() => {
      this.devices = [];
      this.getDevices();
    }, 250);
  }

  public getSelectedDevice(deviceId: string): boolean {
    return this.selectedDevices.some(({ id: selectedDeviceId }) => deviceId === selectedDeviceId);
  }

  public selectDevice(device: DeviceModel): void {
    if (this.multiple) {
      const deviceIndex = this.selectedDevices.findIndex(({ id: selectedDeviceId }) => device.id === selectedDeviceId);
      if (deviceIndex !== -1) {
        this.selectedDevices.splice(deviceIndex, 1);
      } else {
        this.selectedDevices.push(device);
      }
    } else {
      this.overlay.dismiss(device);
    }
  }

  public addDevices(): void {
    this.overlay.dismiss(this.selectedDevices);
  }

  public closeHandler(): void {
    this.overlay.dismiss();
  }
}

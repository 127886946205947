// Angular
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
// Services
import { AccountService } from '@data/services/account.service';

@Directive({
  selector: '[appIfRole]'
})
export class IfRoleDirective {

  private hasView: boolean;

  constructor(private templateRef: TemplateRef<IfRoleDirective>, private viewContainer: ViewContainerRef, private accountService: AccountService) {
    this.hasView = false;
  }

  @Input('appIfRole')
  public set ifRole(role: Array<string>) {
    const { role: accountRole } = this.accountService.getCurrentAccount()!;

    if (role.includes(accountRole) && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!role.includes(accountRole) && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}

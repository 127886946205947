// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Models
import { BusinessUnitModel } from '@data/models';

@Pipe({
  name: 'businessUnits'
})
export class BusinessUnitsPipe implements PipeTransform {

  constructor() {
  }

  public transform(businessUnits: Array<BusinessUnitModel>): string {
    return businessUnits.length ? businessUnits.map(({ name }) => name).join(', ') : 'All';
  }
}

<div class="relative">
  <ng-content></ng-content>
  <div *ngIf="visible" class="flex absolute z-50 bg-white rounded-sm shadow select-none" [ngClass]="{ 'bottom-12': position.includes('top'), 'top-12': position.includes('bottom'), 'left-0': position.includes('left'), 'right-0': position.includes('right') }">
    <div *ngFor="let calendarCard of calendarCards; index as index;" class="flex-shrink-0 w-60 p-2">
      <div class="flex items-center justify-between">
        <div class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full transition-colors hover:bg-gray-50" [ngClass]="{ 'opacity-0 pointer-events-none': previousMonthIsDisabled(index) }" role="button" (click)="previousMonthHandler($event)">
          <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><polygon points="11.1,15.7 3.4,8 11.1,0.3 12.6,1.7 6.3,8 12.6,14.3 "></polygon></svg>
        </div>
        <p class="font-medium text-gray-900 capitalize">{{ (calendarCard.year + '-' + (calendarCard.month + 1)) | date:'LLLL yyyy' }}</p>
        <div class="flex items-center justify-center w-8 h-8 text-gray-600 rounded-full transition-colors hover:bg-gray-50" [ngClass]="{ 'opacity-0 pointer-events-none': nextMonthIsDisabled(index) }" role="button" (click)="nextMonthHandler($event)">
          <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><polygon points="4.9,15.7 3.4,14.3 9.7,8 3.4,1.7 4.9,0.3 12.6,8 "></polygon></svg>
        </div>
      </div>
      <ul class="grid grid-cols-7 my-2 text-center text-xs text-gray-500">
        <li *ngFor="let dayLabel of calendarCard.dayNames" class="capitalize">{{ dayLabel }}</li>
      </ul>
      <div class="grid grid-cols-7 mt-2">
        <ng-template ngFor [ngForOf]="calendarCard.dayOffset">
          <div>&nbsp;</div>
        </ng-template>
        <ng-template ngFor let-day [ngForOf]="calendarCard.days">
          <div class="flex items-center justify-center h-8 text-center text-sm rounded-full" [ngClass]="{ 'text-white bg-blue-500': day.selected, 'text-gray-500 pointer-events-none': day.disabled, 'text-gray-900 transition-colors hover:bg-gray-100': !day.selected && !day.disabled, 'border border-gray-300': day.today && !day.selected }" role="button" (click)="selectDate(calendarCard.year, calendarCard.month, day.date)">{{ day.date }}</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

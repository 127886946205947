// Angular
import { Component } from '@angular/core';
// External
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  constructor(private translateService: TranslateService) {
    this.translateService.setDefaultLang('en');

    if (this.translateService.getBrowserLang() === 'pl') {
      this.translateService.use('pl');
    } else {
      this.translateService.use('en');
    }
  }
}

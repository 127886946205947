// Angular
import { Component, EventEmitter, HostListener, Input, Optional, Output } from '@angular/core';
// Models
import { DialogType, Overlay } from '../../models';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {

  @Input()
  public type!: DialogType;

  @Input()
  public icon!: DialogType;

  @Input()
  public title!: string;

  @Input()
  public description: string | undefined;

  @Input()
  public action: string | undefined;

  @Output()
  public close: EventEmitter<void>;

  constructor(@Optional() private overlay: Overlay<DialogComponent>) {
    this.close = new EventEmitter();
  }

  @HostListener('window:popstate')
  @HostListener('document:keydown.escape')
  public closeHandler(): void {
    this.close.emit();

    if (!!this.overlay) {
      this.overlay.dismiss();
    }
  }
}

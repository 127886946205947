// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
// Services
import { AuthService } from '@data/services/auth.service';
import { AccountService } from '@data/services/account.service';
// External
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private accountService: AccountService) {
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.authValue || !this.authService.authValue.accountId) {
      return this.router.parseUrl(`/auth/login?redirectUrl=${encodeURIComponent(state.url)}`);
    }

    if (!this.accountService.getCurrentAccount()) {
      return this.accountService.getAccount(this.authService.authValue.accountId).pipe(
        tap(account => {
          this.accountService.setCurrentAccount(account);
        }),
        map(() => true),
        catchError(error => {
          this.authService.logout();

          return throwError(error).pipe(
            map(() => state.url === '/auth/login' || this.router.parseUrl(`/auth/login?redirectUrl=${encodeURIComponent(state.url)}`))
          );
        })
      );
    }

    return true;
  }
}

// Angular
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// Services
import { AccountService } from './services/account.service';
import { AuthService } from './services/auth.service';
import { BusinessUnitsService } from './services/business-units.service';
import { CustomerService } from './services/customer.service';
import { DashboardService } from './services/dashboard.service';
import { DeviceService } from './services/device.service';
import { EnumService } from './services/enum.service';
import { FormService } from './services/form.service';
import { OrderService } from './services/order.service';
import { ProtocolService } from './services/protocol.service';

@NgModule({
  imports: [
    HttpClientModule
  ]
})
export class DataModule {

  constructor(@Optional() @SkipSelf() parentModule: DataModule) {
    if (parentModule) {
      throw new Error('DataModule has already been loaded. Import DataModule modules in the AppModule only.');
    }
  }

  public static forRoot(apiUrl: string): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: [
        { provide: 'API_URL', useValue: apiUrl },
        AccountService,
        AuthService,
        BusinessUnitsService,
        CustomerService,
        DashboardService,
        DeviceService,
        EnumService,
        FormService,
        OrderService,
        ProtocolService
      ]
    };
  }
}

// Angular
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent {

  @Input()
  public query: string;

  @Input()
  public placeholder: string;

  @Input()
  public debounce: number;

  private searchDebounceTimeout: number | undefined;

  constructor(private router: Router) {
    this.query = '';
    this.placeholder = 'Szukaj';
    this.debounce = 250;
  }

  public searchHandler(): void {
    window.clearTimeout(this.searchDebounceTimeout);

    this.searchDebounceTimeout = window.setTimeout(async () => {
      await this.router.navigate([], {
        queryParamsHandling: 'merge',
        queryParams: {
          q: this.query,
          page: 1
        }
      });
    }, this.debounce);
  }
}

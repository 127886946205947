// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { EnumsModel } from '../models';
// Services
import { AuthService } from './auth.service';
// External
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class EnumService {

  private enums: BehaviorSubject<EnumsModel | null> | undefined;

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string, private authService: AuthService) {
    this.authService.auth
      .subscribe(() => {
        this.enums = undefined;
      });
  }

  public getEnums(): Observable<EnumsModel | null> {
    if (!!this.enums) {
      return this.enums.pipe(
        filter(enums => !!enums)
      );
    }

    this.enums = new BehaviorSubject<EnumsModel | null>(null);
    return this.httpClient.get<EnumsModel>(`${this.apiUrl}/app-admin/v1/enums`).pipe(
      tap(enums => {
        this.enums!.next(enums);
      })
    );
  }
}

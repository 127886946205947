// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'diffDays'
})
export class DiffDaysPipe implements PipeTransform {

  constructor() {
  }

  public transform(date1: string, date2?: string): number {
    const date1Date = new Date((new Date(date1)).getFullYear(), (new Date(date1)).getMonth(), (new Date(date1)).getDate());
    const date2Date = !!date2 ? new Date((new Date(date2)).getFullYear(), (new Date(date2)).getMonth(), (new Date(date2)).getDate()) : new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate());

    return Math.floor((date2Date.getTime() - date1Date.getTime()) / 1000 / 60 / 60 / 24);
  }
}

// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
// Model
import { AuthModel } from '../models';
// External
import Cookies from 'js-cookie';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {

  public auth: Observable<AuthModel | null>;

  private authSubject: BehaviorSubject<AuthModel | null>;
  private readonly authUrl: string;
  private refreshingAuthToken: boolean;

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
    let auth = null;

    if (!!Cookies.get('token') && !!Cookies.get('refreshToken') && !!Cookies.get('accountId')) {
      auth = {
        token: Cookies.get('token')!,
        refreshToken: Cookies.get('refreshToken')!,
        accountId: Cookies.get('accountId')!
      };
    }

    this.authSubject = new BehaviorSubject<AuthModel | null>(auth);
    this.auth = this.authSubject.asObservable();
    this.authUrl = `${this.apiUrl}/app-admin/v1/jwt`;
    this.refreshingAuthToken = false;
  }

  public get authValue(): AuthModel | null {
    return this.authSubject.value || null;
  }

  public setToken(auth: AuthModel): void {
    Cookies.set('token', auth.token, { expires: 30 });
    Cookies.set('refreshToken', auth.refreshToken, { expires: 30 });
    Cookies.set('accountId', auth.accountId, { expires: 30 });

    this.authSubject.next(auth);
  }

  public getAuthorizationRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (!!this.authValue?.token && !request.url.includes(this.authUrl)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authValue.token}`
        },
      });
    }

    return request;
  }

  public authorizationHandler(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.refreshingAuthToken && !request.url.includes(this.authUrl)) {
      return this.refreshHandler(request, next);
    }

    return next.handle(this.getAuthorizationRequest(request));
  }

  public refreshHandler(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authRefreshToken: Observable<any> = this.refresh();

    return authRefreshToken.pipe(
      switchMap(() => next.handle(this.getAuthorizationRequest(request)))
    );
  }

  public login(username: string, password: string): Observable<AuthModel> {
    return this.httpClient.post<AuthModel>(this.authUrl, { username, password });
  }

  public refresh(): Observable<AuthModel | null> {
    if (this.refreshingAuthToken) {
      return this.auth.pipe(
        first(auth => {
          const { refreshToken: authRefreshToken } = auth!;
          return !!authRefreshToken;
        }),
        finalize(() => {
          this.refreshingAuthToken = false;
        })
      );
    }

    const refreshToken = this.authValue!.refreshToken;

    this.refreshingAuthToken = true;
    this.authSubject.next({ ...this.authSubject.value!, refreshToken: '' });

    return this.httpClient.post<AuthModel>(`${this.authUrl}/refresh`, { refreshToken }).pipe(
      tap(auth => {
        this.setToken({ ...this.authSubject.value, ...auth });
      }),
      finalize(() => {
        this.refreshingAuthToken = false;
      })
    );
  }

  public logout(): void {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    Cookies.remove('accountId');

    this.authSubject.next(null);
  }
}

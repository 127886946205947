<!-- modal -->
<div disableBodyScroll class="fixed z-50 inset-0 overscroll-y-contain overflow-y-auto">
  <div class="relative min-h-full lg:py-16">
    <div class="absolute inset-0 bg-white lg:bg-gray-900 lg:bg-opacity-20" role="button" (click)="closeHandler()"></div>
    <div class="relative bg-white lg:max-w-xl lg:mx-auto lg:rounded lg:shadow-xl">
      <header class="flex p-5">
        <div class="flex-1">
          <p class="font-semibold text-lg text-gray-900">{{ title }}</p>
          <p *ngIf="!!description" class="mt-2 text-gray-600">{{ description }}</p>
        </div>
        <div class="flex-shrink-0">
          <button class="button-icon -my-2 -mr-2" aria-label="close" type="button" (click)="closeHandler()">
            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"></path></svg>
          </button>
        </div>
      </header>
      <main class="px-5 pb-5">
        <ng-content></ng-content>
      </main>
    </div>
  </div>
</div>

// Angular
import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[dateFormat]'
})
export class DateFormatDirective {

  private readonly pattern: string;
  private readonly separator: string;
  private readonly separatorIndexes: Array<number>;

  constructor(private ngControl: NgControl) {
    this.pattern = 'YYYY-MM-DD';
    this.separator = '-';
    this.separatorIndexes = [...this.pattern].reduce<Array<number>>((indexes, char, index) => {
      return char === this.separator ? [...indexes, index + 1] : indexes;
    }, []);
  }

  @HostListener('paste', ['$event'])
  public pasteHandler(event: ClipboardEvent): void {
    event.preventDefault();
  }

  @HostListener('select', ['$event.target'])
  public selectHandler(element: HTMLInputElement): void {
    element.setSelectionRange(element.selectionEnd, element.selectionEnd);
  }

  @HostListener('focus', ['$event.target'])
  public focusHandler(element: HTMLInputElement): void {
    const regexp = new RegExp(/[A-Z]/);

    if (!element.value || !regexp.test(element.value)) {
      return;
    }

    window.setTimeout(() => {
      const { index } = regexp.exec(element.value)!;
      element.setSelectionRange(index, index);
    });
  }

  @HostListener('input', ['$event'])
  public inputHandler(event: InputEvent): void {
    const element = event.target as HTMLInputElement;

    let value = element.value;
    let selection = element.selectionStart!;

    if (value.length > 1) {
      const selectionSeparator = this.separatorIndexes.includes(selection);

      if (event.inputType === 'insertText') {
        let startValue = value.slice(0, selection - 1);
        let endValue = value.slice(selection + 1);
        let replaceValue = value[selection - 1].replace(/[^\d]/g, this.pattern[selection - 1]);

        if (selectionSeparator) {
          startValue += this.separator;
          endValue = value.slice(selection + 2);
          replaceValue = value[selection - 1].replace(/[^\d]/g, this.pattern[selection]);

          if (/\d/g.test(value[selection - 1])) {
            selection++;
          }
        }

        value = `${startValue}${replaceValue}${endValue}`;
      }

      if (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward') {
        const startValue = value.slice(0, selection);
        const endValue = value.slice(selection);
        const replaceValue = this.pattern[selection];

        value = `${startValue}${replaceValue}${endValue}`;
      }

      if (event.inputType === 'deleteContentForward') {
        selection++;
      }
    } else {
      value = value.replace(/[^\d]/g, '');
      value = `${value}${this.pattern.slice(value.length)}`;
    }

    this.ngControl.control!.patchValue(value.slice(0, this.pattern.length));

    element.setSelectionRange(selection, selection);
  }
}

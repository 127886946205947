// Angular
import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[toggle]',
  exportAs: 'toggle'
})
export class ToggleDirective {

  @Input()
  public active: boolean;

  @Output()
  public activeChange: EventEmitter<boolean>;

  constructor() {
    this.active = false;
    this.activeChange = new EventEmitter();
  }

  @HostListener('click')
  public clickHandler(): void {
    this.active = !this.active;
    this.activeChange.emit(this.active);
  }
}

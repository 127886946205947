// Angular
import { formatDate, FormStyle, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { Component } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
// External
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'select-date',
  templateUrl: './select-date.component.html'
})
export class SelectDateComponent implements ControlValueAccessor {

  public value: string | null | undefined;

  public date: string;
  public month: string;
  public year: string;

  public readonly monthNames: ReadonlyArray<string>;

  constructor(private ngControl: NgControl, private translateService: TranslateService) {
    this.ngControl.valueAccessor = this;

    this.date = '';
    this.month = '';
    this.year = '';

    this.monthNames = getLocaleMonthNames(this.translateService.currentLang, FormStyle.Standalone, TranslationWidth.Wide);
  }

  public get invalid(): boolean | null {
    return this.ngControl.invalid && this.ngControl.touched;
  }

  public changeDate(): void {
    if (!!this.date && !!this.month && !!this.year) {
      this.changeHandler(formatDate(new Date(+this.year, +this.month, +this.date), 'yyyy-MM-dd', this.translateService.currentLang));
    } else {
      this.changeHandler(null);
    }
  }

  public blurHandler(): void {
    this.touchedHandler();
  }

  public changeHandler(value: string | null): void {
  }

  public touchedHandler(): void {
  }

  public writeValue(value: string): void {
    this.value = value;

    if (!!value) {
      const [year, month, date] = value.split('-');

      this.date = date;
      this.month = `${+month - 1}`;
      this.year = year;
    } else {
      this.date = '';
      this.month = '';
      this.year = '';
    }
  }

  public registerOnChange(callback: any): void {
    this.changeHandler = callback;
  }

  public registerOnTouched(callback: any): void {
    this.touchedHandler = callback;
  }
}

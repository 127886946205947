<div class="inline-flex space-x-2">
  <button *ngIf="pageCount >= 10" class="button button-outline w-10 h-10 p-0 rounded" aria-label="first" [disabled]="page === 1" type="button" (click)="pageChange(1)">
    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M14,16c-0.282,0-0.562-0.118-0.76-0.35l-6-7c-0.321-0.374-0.321-0.927,0-1.301l6-7 c0.36-0.42,0.992-0.468,1.41-0.108c0.419,0.359,0.468,0.99,0.108,1.409L9.317,8l5.442,6.35c0.359,0.419,0.311,1.05-0.108,1.409 C14.462,15.921,14.231,16,14,16z"></path><path d="M8,16c-0.282,0-0.562-0.118-0.76-0.35l-6-7c-0.321-0.374-0.321-0.927,0-1.301l6-7 C7.6-0.07,8.232-0.118,8.651,0.241C9.07,0.601,9.119,1.231,8.759,1.65L3.317,8l5.442,6.35c0.359,0.419,0.311,1.05-0.108,1.409 C8.462,15.921,8.231,16,8,16z"></path></svg>
  </button>
  <button class="button button-outline w-10 h-10 p-0 rounded" aria-label="previous" [disabled]="page === 1" type="button" (click)="pagePrevious()">
    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><polygon points="11.1,15.7 3.4,8 11.1,0.3 12.6,1.7 6.3,8 12.6,14.3"></polygon></svg>
  </button>
  <div class="hidden md:flex relative z-10 space-x-2">
    <a *ngFor="let index of pages" routerLink="." [queryParams]="{ page: index }" [queryParamsHandling]="'merge'" class="button w-10 h-10 p-0" [ngClass]="{ 'button-outline': page !== index, 'button-solid-blue pointer-events-none': page === index }">{{ index }}</a>
  </div>
  <div class="relative z-10 w-40 md:hidden">
    <p class="flex items-center justify-center h-10 py-2 px-3 text-center text-gray-900 border border-gray-200 rounded">{{ 'pagination.current' | translate:{ page: page, count: pageCount } }}</p>
    <select class="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer" [ngModel]="page" (ngModelChange)="pageChange($event)" aria-label="page" tabindex="-1">
      <option value="" selected disabled>{{ 'pagination.select' | translate }}</option>
      <option *ngFor="let index of allPages" [ngValue]="index">{{ index }}</option>
    </select>
  </div>
  <button class="button button-outline w-10 h-10 p-0 rounded" aria-label="next" [disabled]="page === pageCount" type="button" (click)="pageNext()">
    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><polygon points="4.9,15.7 3.4,14.3 9.7,8 3.4,1.7 4.9,0.3 12.6,8"></polygon></svg>
  </button>
  <button *ngIf="pageCount >= 10" class="button button-outline w-10 h-10 p-0 rounded" aria-label="last" [disabled]="page === pageCount" type="button" (click)="pageChange(pageCount)">
    <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M2,16c-0.23,0-0.462-0.079-0.65-0.241c-0.419-0.359-0.468-0.99-0.108-1.409L6.683,8 L1.241,1.65C0.881,1.231,0.93,0.601,1.349,0.241c0.419-0.36,1.05-0.312,1.41,0.108l6,7c0.321,0.374,0.321,0.927,0,1.301l-6,7 C2.562,15.882,2.281,16,2,16z"></path><path d="M8,16c-0.23,0-0.462-0.079-0.65-0.241c-0.419-0.359-0.468-0.99-0.108-1.409L12.683,8L7.241,1.65 C6.881,1.231,6.93,0.601,7.349,0.241C7.768-0.119,8.4-0.071,8.759,0.35l6,7c0.321,0.374,0.321,0.927,0,1.301l-6,7 C8.562,15.882,8.281,16,8,16z"></path></svg>
  </button>
</div>

// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, NgLocaleLocalization } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Components
import { DialogComponent } from './components/dialog';
import { DrawerComponent } from './components/drawer';
import { ModalComponent } from './components/modal';
import { NotificationComponent } from './components/notification';
import { NotificationsComponent } from './components/notifications';
import { PaginationComponent } from './components/pagination';
import { StatusComponent } from './components/status';
// Directives
import { BlurDirective } from './directives/blur';
import { BodyScrollDirective } from './directives/body-scroll';
import { DropdownDirective } from './directives/dropdown';
import { ScrollFixedDirective } from './directives/scroll-fixed';
import { ToggleDirective } from './directives/toggle';
// Services
import { DialogService } from './services/dialog.service';
import { NotificationService } from './services/notification.service';
import { OverlayService } from './services/overlay.service';
// External
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [
    DialogComponent,
    DrawerComponent,
    ModalComponent,
    NotificationComponent,
    NotificationsComponent,
    PaginationComponent,
    StatusComponent,
    BlurDirective,
    BodyScrollDirective,
    DropdownDirective,
    ScrollFixedDirective,
    ToggleDirective
  ],
  providers: [
    NgLocaleLocalization
  ],
  exports: [
    DialogComponent,
    DrawerComponent,
    ModalComponent,
    NotificationComponent,
    NotificationsComponent,
    PaginationComponent,
    StatusComponent,
    BlurDirective,
    BodyScrollDirective,
    DropdownDirective,
    ScrollFixedDirective,
    ToggleDirective
  ]
})
export class CoreModule {

  constructor() {
  }

  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        DialogService,
        NotificationService,
        OverlayService
      ]
    };
  }
}

// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Models
import { diffDays } from '@libs/core/models';

@Pipe({
  name: 'inspectionDateStatus'
})
export class InspectionDateStatusPipe implements PipeTransform {

  constructor() {
  }

  public transform(inspectionDate: string): string {
    const inspectionDateCount = diffDays(new Date(), new Date(inspectionDate));

    if (inspectionDateCount < 0) {
      return 'danger';
    } else if (inspectionDateCount >= 0 && inspectionDateCount < 92) {
      return 'warning';
    } else {
      return 'success';
    }
  }
}

// Angular
import { Directive, HostBinding } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[ngModel], [formControl], [formControlName]'
})
export class ControlStatusDirective {

  constructor(private ngControl: NgControl) {
  }

  @HostBinding('class.valid')
  public get valid(): boolean | null {
    return this.ngControl.valid && this.ngControl.touched;
  }

  @HostBinding('class.invalid')
  public get invalid(): boolean | null {
    return this.ngControl.invalid && this.ngControl.touched;
  }
}

// Angular
import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
// Models
import { AccountModel } from '@data/models';
import { Overlay } from '@libs/core/models';
// Services
import { AccountService } from '@data/services/account.service';
import { NotificationService } from '@libs/core/services/notification.service';
// External
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-account-password-modal',
  templateUrl: './account-password-modal.component.html'
})
export class AccountPasswordModalComponent {

  @Input()
  public account!: AccountModel;

  public formSending: boolean;

  constructor(private overlay: Overlay<AccountPasswordModalComponent>,
              private accountService: AccountService,
              private notificationService: NotificationService,
              private translateService: TranslateService) {
    this.formSending = false;
  }

  public testPassword(password: string, regex: string): boolean {
    return new RegExp(regex).test(password);
  }

  public submitForm(form: NgForm): void {
    form.control.markAllAsTouched();

    if (form.invalid || this.formSending) {
      return;
    }

    const { id: accountId } = this.account;
    const { currentPassword, newPassword } = form.value;

    this.formSending = true;
    this.accountService.changeAccountPassword(accountId, currentPassword, newPassword).pipe(
      finalize(() => {
        this.formSending = false;
      })
    ).subscribe({
      next: async () => {
        const notification = await firstValueFrom(this.translateService.get('notification.success.save'));
        this.notificationService.showDanger(notification);
        this.overlay.dismiss();
      },
      error: async () => {
        const notification = await firstValueFrom(this.translateService.get('notification.error.save'));
        this.notificationService.showDanger(notification);
        window.scrollTo(0, 0);
      }
    });
  }

  public closeHandler(): void {
    this.overlay.dismiss();
  }
}

// Angular
import { formatDate, FormStyle, getLocaleDayNames, TranslationWidth } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  Output
} from '@angular/core';
// Directives
import { DatepickerInputDirective } from '../../directives/datepicker-input';
// Models
import { CalendarCard, CalendarCardDay, diffDays, isToday } from '../../models';
// External
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html'
})
export class DatepickerComponent implements AfterContentInit, OnDestroy {

  @ContentChild(DatepickerInputDirective)
  public datepickerInputDirective!: DatepickerInputDirective;

  @Input()
  public firstDay: number;

  @Input()
  public cardsCount: number;

  @Input()
  public minDate: Date | undefined;

  @Input()
  public maxDate: Date | undefined;

  @Input()
  public exclusionDates: Array<Date>;

  @Input()
  public position: string;

  @Output()
  public selectedDateChange: EventEmitter<Date>;

  public selectedDate: Date | undefined;

  public calendarCards: Array<CalendarCard<CalendarCardDay>>;

  public visible: boolean;

  private destroy: Subject<void>;

  constructor(private elementRef: ElementRef<HTMLElement>, @Inject(LOCALE_ID) private locale: string) {
    this.firstDay = 1;
    this.cardsCount = 1;
    this.exclusionDates = [];
    this.position = 'left bottom';

    this.selectedDateChange = new EventEmitter();

    this.calendarCards = [];

    this.visible = false;

    this.destroy = new Subject();
  }

  ngAfterContentInit(): void {
    this.datepickerInputDirective.submitted.pipe(
      takeUntil(this.destroy)
    ).subscribe(() => {
      this.submitHandler();
    });

    this.datepickerInputDirective.focused.pipe(
      takeUntil(this.destroy)
    ).subscribe(() => {
      this.focusHandler();
    });

    this.datepickerInputDirective.blurred.pipe(
      takeUntil(this.destroy)
    ).subscribe(event => {
      this.blurHandler(event);
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  @HostListener('document:mousedown', ['$event'])
  public outsideClickHandler(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains((event.target as HTMLElement))) {
      this.hideCalendar();
    }
  }

  public submitHandler(): void {
    const value = this.datepickerInputDirective.control.value;
    const valueValid = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value);
    const [year, month, date] = value.split('-');

    if (valueValid && !this.isDisabled(+year, +month - 1, +date)) {
      this.selectedDate = new Date(+year, +month - 1, +date);
      this.datepickerInputDirective.control.patchValue(this.getFormattedDate());
      this.selectedDateChange.emit(this.selectedDate);
      this.getSelectedCalendarCards();
    }
  }

  public focusHandler(): void {
    const value = this.datepickerInputDirective.control.value;
    const valueValid = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(value);
    const [year, month, date] = value.split('-');

    if (!this.selectedDate && valueValid && !this.isDisabled(+year, +month - 1, +date)) {
      this.selectedDate = new Date(+year, +month - 1, +date);
      this.datepickerInputDirective.control.patchValue(this.getFormattedDate());
      this.selectedDateChange.emit(this.selectedDate);
    }

    this.getSelectedCalendarCards();
    this.showCalendar();
  }

  public blurHandler(event: FocusEvent): void {
    if (this.datepickerInputDirective.control.value !== this.getFormattedDate()) {
      this.datepickerInputDirective.control.patchValue(this.getFormattedDate());
    }

    if (!!event.relatedTarget) {
      this.hideCalendar();
    }
  }

  public showCalendar(): void {
    this.visible = true;
  }

  public hideCalendar(): void {
    this.visible = false;
  }

  public getSelectedCalendarCards(): void {
    const calendarStartDate = this.selectedDate || new Date();
    this.getCalendarCards(calendarStartDate.getFullYear(), calendarStartDate.getMonth());
  }

  public getCalendarCards(year: number, month: number): void {
    this.calendarCards = [];

    for (let i = 0; i < this.cardsCount; i++) {
      const date = new Date(year, month + i);
      this.calendarCards.push(this.getCalendarCard(date.getFullYear(), date.getMonth()));
    }
  }

  public getCalendarCard(year: number, month: number): CalendarCard<CalendarCardDay> {
    const firstDay = new Date(year, month);
    const lastDay = new Date(year, month + 1, 0);

    const offset = firstDay.getDay() - this.firstDay;
    const dayOffset = offset < 0 ? Array(7 + offset) : Array(offset);
    const localeDayNames = getLocaleDayNames(this.locale, FormStyle.Standalone, TranslationWidth.Short);
    const dayNames = [...JSON.parse(JSON.stringify(localeDayNames)).slice(this.firstDay), ...JSON.parse(JSON.stringify(localeDayNames)).splice(0, this.firstDay)];

    const days = [];

    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push({
        date: i,
        today: isToday(year, month, i),
        selected: this.isSelected(year, month, i),
        disabled: this.isDisabled(year, month, i)
      });
    }

    return { year, month, days, dayNames, dayOffset };
  }

  public selectDate(year: number, month: number, date: number): void {
    this.selectedDate = new Date(year, month, date);
    this.datepickerInputDirective.control.patchValue(this.getFormattedDate());
    this.selectedDateChange.emit(this.selectedDate);
    this.hideCalendar();
  }

  public previousMonthHandler(event: MouseEvent): void {
    event.stopPropagation();
    this.previousMonth();
  }

  public nextMonthHandler(event: MouseEvent): void {
    event.stopPropagation();
    this.nextMonth();
  }

  public previousMonth(): void {
    let [{ year, month }] = this.calendarCards;

    if (--month < 0) {
      month = 11;
      year--;
    }

    this.getCalendarCards(year, month);
  }

  public nextMonth(): void {
    let [{ year, month }] = this.calendarCards;

    if (++month > 11) {
      month = 0;
      year++;
    }

    this.getCalendarCards(year, month);
  }

  public previousMonthIsDisabled(index: number): boolean {
    const { year, month } = this.calendarCards[index];
    return index !== 0 || (!!this.minDate && year === this.minDate.getFullYear() && month === this.minDate.getMonth());
  }

  public nextMonthIsDisabled(index: number): boolean {
    const { year, month } = this.calendarCards[index];
    return index !== this.calendarCards.length - 1 || (!!this.maxDate && year === this.maxDate.getFullYear() && month === this.maxDate.getMonth());
  }

  public isSelected(year: number, month: number, date: number): boolean {
    return !!this.selectedDate && diffDays(this.selectedDate, new Date(year, month, date)) === 0;
  }

  public isDisabled(year: number, month: number, date: number): boolean {
    const disabledDate = new Date(year, month, date);
    const lowerMinDate = !!this.minDate && diffDays(this.minDate, disabledDate) < 0;
    const greaterMaxDate = !!this.maxDate && diffDays(this.maxDate, disabledDate) > 0;
    const includeExclusionDates = this.exclusionDates.some(exclusionDate => exclusionDate.getFullYear() === year && exclusionDate.getMonth() === month && exclusionDate.getDate() === date);

    return lowerMinDate || greaterMaxDate || includeExclusionDates;
  }

  public getFormattedDate(): string {
    return !!this.selectedDate ? formatDate(this.selectedDate, 'yyyy-MM-dd', this.locale) : '';
  }
}

<div class="grid grid-cols-4 gap-x-3">
  <input [(ngModel)]="date" (ngModelChange)="changeDate()" [ngModelOptions]="{ standalone: true }" [replacePattern]="'[^\\d]'" class="form-control" [class.invalid]="invalid" aria-label="date" [placeholder]="'forms.day' | translate" inputmode="decimal" minlength="1" maxlength="2" type="text" (blur)="blurHandler()">
  <div class="relative col-span-2">
    <select [(ngModel)]="month" (ngModelChange)="changeDate()" [ngModelOptions]="{ standalone: true }" class="form-control pr-10" [class.invalid]="invalid" aria-label="month" (blur)="blurHandler()">
      <option value="" selected disabled>{{ 'forms.month' | translate }}</option>
      <option *ngFor="let month of monthNames; index as index;" [value]="index">{{ month }}</option>
    </select>
    <div class="flex items-center justify-center absolute top-0 right-0 z-10 w-10 h-10 text-gray-600 pointer-events-none">
      <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><polygon points="8,11.4 2.6,6 4,4.6 8,8.6 12,4.6 13.4,6"></polygon></svg>
    </div>
  </div>
  <input [(ngModel)]="year" (ngModelChange)="changeDate()" [ngModelOptions]="{ standalone: true }" [replacePattern]="'[^\\d]'" class="form-control" [class.invalid]="invalid" aria-label="year" [placeholder]="'forms.year' | translate" inputmode="decimal" minlength="4" maxlength="4" type="text" (blur)="blurHandler()">
</div>

// Angular
import { Directive, HostListener, Input } from '@angular/core';
// Components
import { DeviceActivityDrawerComponent } from '../../components/device-activity-drawer';
// Models
import { DeviceActivityModel } from '@data/models';
// Services
import { OverlayService } from '@libs/core/services/overlay.service';

@Directive({
  selector: '[appOpenDeviceActivityDrawer]'
})
export class OpenDeviceActivityDrawerDirective {

  @Input('appOpenDeviceActivityDrawer')
  public deviceActivity!: DeviceActivityModel;

  constructor(private overlayService: OverlayService) {
  }

  @HostListener('click')
  public clickHandler(): void {
    const overlay = this.overlayService.create(DeviceActivityDrawerComponent);
    overlay.instance.deviceActivity = this.deviceActivity;
    overlay.present();
  }
}

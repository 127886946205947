// Angular
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent {

  @Input()
  public title!: string;

  @Input()
  public description: string | undefined;

  @Output()
  public close: EventEmitter<void>;

  constructor() {
    this.close = new EventEmitter();
  }

  @HostListener('window:popstate')
  @HostListener('document:keydown.escape')
  public closeHandler(): void {
    this.close.emit();
  }
}

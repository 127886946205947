// Angular
import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html'
})
export class DrawerComponent {

  @Output()
  public close: EventEmitter<void>;

  constructor() {
    this.close = new EventEmitter();
  }

  @HostListener('window:popstate')
  @HostListener('document:keydown.escape')
  public closeHandler(): void {
    this.close.emit();
  }
}

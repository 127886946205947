// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Guards
import { AdminGuard } from './guards/admin.guard';
// Layouts
import { BaseLayoutComponent } from './layouts/base-layout';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'report', loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule) },
  { path: 'shared/orders', loadChildren: () => import('./modules/shared-order/shared-order.module').then(m => m.SharedOrderModule) },
  { path: 'shared/protocols', loadChildren: () => import('./modules/shared-protocol/shared-protocol.module').then(m => m.SharedProtocolModule) },
  {
    path: '',
    canActivate: [AdminGuard],
    component: BaseLayoutComponent,
    children: [
      { path: 'accounts', loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule) },
      { path: 'customers', loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule) },
      { path: 'devices', loadChildren: () => import('./modules/devices/devices.module').then(m => m.DevicesModule) },
      { path: 'events', loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule) },
      { path: 'map', loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule) },
      { path: 'orders', loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule) },
      { path: 'protocols', loadChildren: () => import('./modules/protocols/protocols.module').then(m => m.ProtocolsModule) },
      { path: '', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) }
    ]
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

  constructor() {
  }
}

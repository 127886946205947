<div class="flex p-4 text-white rounded" [ngClass]="{ 'bg-green-600': type === 'success', 'bg-blue-800': type === 'info', 'bg-yellow-600': type === 'warning', 'bg-red-600': type === 'danger' }">
  <div class="flex-shrink-0 mr-2">
    <svg *ngIf="icon === 'success'" class="w-6 h-6 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg"><circle fill="white" cx="12" cy="12" r="12"></circle><path d="M16.2929 7.29298L9.99992 13.586L7.70692 11.293C7.51832 11.1108 7.26571 11.01 7.00352 11.0123C6.74132 11.0146 6.49051 11.1198 6.3051 11.3052C6.11969 11.4906 6.01452 11.7414 6.01224 12.0036C6.00997 12.2658 6.11076 12.5184 6.29292 12.707L9.29292 15.707C9.48045 15.8945 9.73475 15.9998 9.99992 15.9998C10.2651 15.9998 10.5194 15.8945 10.7069 15.707L17.7069 8.70698C17.8891 8.51838 17.9899 8.26578 17.9876 8.00358C17.9853 7.74138 17.8801 7.49057 17.6947 7.30516C17.5093 7.11975 17.2585 7.01458 16.9963 7.01231C16.7341 7.01003 16.4815 7.11082 16.2929 7.29298Z"></path></svg>
    <svg *ngIf="icon === 'info'" class="w-6 h-6 text-blue-800 fill-current" xmlns="http://www.w3.org/2000/svg"><circle fill="white" cx="12" cy="12" r="12"></circle><path d="M13.1921 11H10.8091V18H13.1921V11Z"></path><path d="M12 9C12.8284 9 13.5 8.32843 13.5 7.5C13.5 6.67157 12.8284 6 12 6C11.1716 6 10.5 6.67157 10.5 7.5C10.5 8.32843 11.1716 9 12 9Z"></path></svg>
    <svg *ngIf="icon === 'warning'" class="w-6 h-6 text-yellow-600 fill-current" xmlns="http://www.w3.org/2000/svg"><circle fill="white" cx="12" cy="12" r="12"></circle><path d="M12.9608 13.633L13.3588 6H10.6558L11.0548 13.633H12.9608Z"></path><path d="M12 18C12.8284 18 13.5 17.3284 13.5 16.5C13.5 15.6716 12.8284 15 12 15C11.1716 15 10.5 15.6716 10.5 16.5C10.5 17.3284 11.1716 18 12 18Z"></path></svg>
    <svg *ngIf="icon === 'danger'" class="w-6 h-6 text-red-600 fill-current" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="M10.4809 0.629225L0.629225 10.4809C-0.209742 11.3199 -0.209742 12.6801 0.629225 13.5191L10.4809 23.3708C11.3199 24.2097 12.6801 24.2097 13.5191 23.3708L23.3708 13.5191C24.2097 12.6801 24.2097 11.3199 23.3708 10.4809L13.5191 0.629225C12.6801 -0.209742 11.3199 -0.209742 10.4809 0.629225Z"></path><path d="M12.9608 13.633L13.3588 6H10.6558L11.0548 13.633H12.9608Z"></path><path d="M12 18C12.8284 18 13.5 17.3284 13.5 16.5C13.5 15.6716 12.8284 15 12 15C11.1716 15 10.5 15.6716 10.5 16.5C10.5 17.3284 11.1716 18 12 18Z"></path></svg>
    <ng-content select="[slot=icon]"></ng-content>
  </div>
  <div class="flex-1">
    <p *ngIf="!!message" class="font-semibold">{{ message }}</p>
    <ng-content select="[slot=message]"></ng-content>
  </div>
  <div *ngIf="canClose" class="flex-shrink-0 -my-1 ml-2 -mr-1">
    <button class="flex items-center justify-center w-8 h-8 focus:outline-none" aria-label="close" type="button" (click)="closeHandler()">
      <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"></path></svg>
    </button>
  </div>
</div>

// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// Models
import {
  AcceptanceOrderModel,
  CreateDeinstallationOrderModel,
  CreateInstallationOrderModel,
  CreateRepairOrderModel,
  OrderModel,
  OrdersFilterModel,
  PaginationModel
} from '../models';
// External
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OrderService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public createInstallationOrder(data: CreateInstallationOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/installationOrder`, data);
  }

  public editInstallationOrder(id: string, data: CreateInstallationOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/installationOrder/${id}`, data);
  }

  public createDeinstallationOrder(data: CreateDeinstallationOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/deinstallationOrder`, data);
  }

  public editDeinstallationOrder(id: string, data: CreateDeinstallationOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/deinstallationOrder/${id}`, data);
  }

  public createRepairOrder(data: CreateRepairOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/repairOrder`, data);
  }

  public editRepairOrder(id: string, data: CreateRepairOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/repairOrder/${id}`, data);
  }

  public getOrders(filter: OrdersFilterModel): Observable<PaginationModel<OrderModel>> {
    let params = new HttpParams();

    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          params = params.append(`${key}[]`, item);
        });
      } else if (value === true) {
        params = params.append(key, '1');
      } else if (value === false) {
        params = params.append(key, '0');
      } else {
        params = params.append(key, value);
      }
    });

    return this.httpClient.get<PaginationModel<OrderModel>>(`${this.apiUrl}/app-admin/v1/orders`, { params });
  }

  public exportOrders(filter: Partial<OrdersFilterModel>): Observable<{ name: string; file: Blob; }> {
    let params = new HttpParams();

    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          params = params.append(`${key}[]`, item);
        });
      } else if (value === true) {
        params = params.append(key, '1');
      } else if (value === false) {
        params = params.append(key, '0');
      } else {
        params = params.append(key, value);
      }
    });

    return this.httpClient.request('GET', `${this.apiUrl}/app-admin/v1/orders/export`, {
      responseType: 'blob',
      observe: 'response',
      params
    }).pipe(
      map(({ headers, body: file }) => {
        const filename = headers.get('content-disposition')!.split('; ').find(item => item.includes('filename'))!;
        const name = filename.replace('filename=', '');

        return {
          name,
          file: file!
        };
      })
    );
  }

  public getOrder(id: string): Observable<OrderModel> {
    return this.httpClient.get<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}`);
  }

  public openOrder(id: string): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}/open`, null);
  }

  public closeOrder(id: string): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}/close`, null);
  }

  public archiveOrder(id: string): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}/archive`, null);
  }

  public acceptanceOrder(id: string, data: AcceptanceOrderModel): Observable<OrderModel> {
    return this.httpClient.post<OrderModel>(`${this.apiUrl}/app-admin/v1/orders/${id}/acceptance`, data);
  }

  public shareOrder(id: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-admin/v1/orders/${id}/share`, null);
  }
}

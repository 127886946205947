// Angular
import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[datepickerInput]'
})
export class DatepickerInputDirective {

  @Output()
  public submitted: EventEmitter<void>;

  @Output()
  public focused: EventEmitter<void>;

  @Output()
  public blurred: EventEmitter<FocusEvent>;

  public control: AbstractControl;

  constructor(private ngControl: NgControl) {
    this.submitted = new EventEmitter();
    this.focused = new EventEmitter();
    this.blurred = new EventEmitter();

    this.control = this.ngControl.control!;
  }

  @HostListener('keydown.enter', ['$event'])
  public enterHandler(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.submitted.emit();
  }

  @HostListener('focus', ['$event'])
  public focusHandler(event: FocusEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.focused.emit();
  }

  @HostListener('blur', ['$event'])
  public blurHandler(event: FocusEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.blurred.emit(event);
  }
}

// Angular
import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[scrollFixed]'
})
export class ScrollFixedDirective implements OnInit {

  @Input()
  public offsetTop: number;

  private elementTop: number;
  private elementWidth: number;

  constructor(private elementRef: ElementRef<HTMLElement>) {
    this.offsetTop = 0;

    this.elementTop = 0;
    this.elementWidth = 0;
  }

  ngOnInit(): void {
    this.elementTop = this.elementRef.nativeElement.getBoundingClientRect().top;
    this.elementWidth = this.elementRef.nativeElement.getBoundingClientRect().width;
  }

  @HostListener('window:resize')
  public resizeHandler(): void {
    this.elementRef.nativeElement.style.removeProperty('position');
    this.elementRef.nativeElement.style.removeProperty('top');
    this.elementRef.nativeElement.style.removeProperty('width');

    this.elementTop = this.elementRef.nativeElement.getBoundingClientRect().top;
    this.elementWidth = this.elementRef.nativeElement.getBoundingClientRect().width;
  }

  @HostListener('window:scroll')
  public scrollHandler(): void {
    if (window.innerWidth < 1024) {
      return;
    }

    if (window.scrollY >= (this.elementTop - this.offsetTop)) {
      this.elementRef.nativeElement.style.position = 'fixed';
      this.elementRef.nativeElement.style.top = `${this.offsetTop}px`;
      this.elementRef.nativeElement.style.width = `${this.elementWidth}px`;
    } else {
      this.elementRef.nativeElement.style.removeProperty('position');
      this.elementRef.nativeElement.style.removeProperty('top');
      this.elementRef.nativeElement.style.removeProperty('width');
    }
  }
}

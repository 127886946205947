<app-modal [title]="'accounts.password.title' | translate" (close)="closeHandler()">
  <form #form="ngForm" action="" autocomplete="off" (ngSubmit)="submitForm(form)">
    <div class="space-y-2">
      <p class="font-semibold text-sm text-gray-900">{{ 'accounts.password.current' | translate }}</p>
      <div class="relative">
        <input ngModel class="form-control pr-10" name="currentPassword" aria-label="currentPassword" [placeholder]="'accounts.password.current' | translate" [attr.type]="currentPasswordToggle.active ? 'text' : 'password'" required>
        <button #currentPasswordToggle="toggle" toggle class="flex items-center justify-center absolute top-0 right-0 z-10 w-10 h-10 text-gray-900 focus:outline-none" tabindex="-1" aria-label="toggle" type="button">
          <svg *ngIf="!currentPasswordToggle.active" class="w-6 h-6 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2"><path fill="none" stroke-miterlimit="10" d="M2,12c0,0,4-7,10-7 c6,0,10,7,10,7s-4,7-10,7C6,19,2,12,2,12z"></path><circle fill="none" stroke-miterlimit="10" cx="12" cy="12" r="3"></circle></g></svg>
          <svg *ngIf="currentPasswordToggle.active" class="w-6 h-6 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2"><path fill="none" stroke-miterlimit="10" d="M2,12c0,0,4-7,10-7 c6,0,10,7,10,7s-4,7-10,7C6,19,2,12,2,12z"></path><circle fill="none" stroke-miterlimit="10" cx="12" cy="12" r="3"></circle><line fill="none" stroke-miterlimit="10" x1="3" y1="21" x2="21" y2="3"></line></g></svg>
        </button>
      </div>
    </div>
    <div class="mt-4 space-y-2">
      <p class="font-semibold text-sm text-gray-900">{{ 'accounts.password.new' | translate }}</p>
      <div class="relative">
        <input ngModel #newPasswordModel="ngModel" class="form-control pr-10" name="newPassword" aria-label="newPassword" [placeholder]="'accounts.password.new' | translate" [attr.type]="newPasswordToggle.active ? 'text' : 'password'" required passwordStrength>
        <button #newPasswordToggle="toggle" toggle class="flex items-center justify-center absolute top-0 right-0 z-10 w-10 h-10 text-gray-900 focus:outline-none" tabindex="-1" aria-label="toggle" type="button">
          <svg *ngIf="!newPasswordToggle.active" class="w-6 h-6 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2"><path fill="none" stroke-miterlimit="10" d="M2,12c0,0,4-7,10-7 c6,0,10,7,10,7s-4,7-10,7C6,19,2,12,2,12z"></path><circle fill="none" stroke-miterlimit="10" cx="12" cy="12" r="3"></circle></g></svg>
          <svg *ngIf="newPasswordToggle.active" class="w-6 h-6 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2"><path fill="none" stroke-miterlimit="10" d="M2,12c0,0,4-7,10-7 c6,0,10,7,10,7s-4,7-10,7C6,19,2,12,2,12z"></path><circle fill="none" stroke-miterlimit="10" cx="12" cy="12" r="3"></circle><line fill="none" stroke-miterlimit="10" x1="3" y1="21" x2="21" y2="3"></line></g></svg>
        </button>
      </div>
    </div>
    <ul class="grid grid-cols-2 pag-x-4 gap-y-2 mt-4">
      <li class="flex items-center gap-2" [ngClass]="{ 'text-gray-600': !testPassword(newPasswordModel.value, '[a-z]') && (!newPasswordModel.touched || !newPasswordModel.invalid), 'text-red-600': !testPassword(newPasswordModel.value, '[a-z]') && newPasswordModel.touched && newPasswordModel.invalid, 'text-green-600': testPassword(newPasswordModel.value, '[a-z]') }">
        <div class="grid place-content-center flex-shrink-0 w-4 h-4">
          <div *ngIf="!testPassword(newPasswordModel.value, '[a-z]') && (!newPasswordModel.touched || !newPasswordModel.invalid)" class="w-1 h-1 bg-gray-600 rounded-full"></div>
          <svg *ngIf="!testPassword(newPasswordModel.value, '[a-z]') && newPasswordModel.touched && newPasswordModel.invalid" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.7 1.3C14.3 0.9 13.7 0.9 13.3 1.3L8 6.6L2.7 1.3C2.3 0.9 1.7 0.9 1.3 1.3C0.9 1.7 0.9 2.3 1.3 2.7L6.6 8L1.3 13.3C0.9 13.7 0.9 14.3 1.3 14.7C1.5 14.9 1.7 15 2 15C2.3 15 2.5 14.9 2.7 14.7L8 9.4L13.3 14.7C13.5 14.9 13.8 15 14 15C14.2 15 14.5 14.9 14.7 14.7C15.1 14.3 15.1 13.7 14.7 13.3L9.4 8L14.7 2.7C15.1 2.3 15.1 1.7 14.7 1.3Z"/></svg>
          <svg *ngIf="testPassword(newPasswordModel.value, '[a-z]')" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.3 2.3L5 11.6L1.7 8.3C1.3 7.9 0.7 7.9 0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7L4.3 13.7C4.5 13.9 4.7 14 5 14C5.3 14 5.5 13.9 5.7 13.7L15.7 3.7C16.1 3.3 16.1 2.7 15.7 2.3C15.3 1.9 14.7 1.9 14.3 2.3Z"/></svg>
        </div>
        <p class="flex-1 text-sm">{{ 'forms.password.lowercase' | translate }}</p>
      </li>
      <li class="flex items-center gap-2" [ngClass]="{ 'text-gray-600': !testPassword(newPasswordModel.value, '[A-Z]') && (!newPasswordModel.touched || !newPasswordModel.invalid), 'text-red-600': !testPassword(newPasswordModel.value, '[A-Z]') && newPasswordModel.touched && newPasswordModel.invalid, 'text-green-600': testPassword(newPasswordModel.value, '[A-Z]') }">
        <div class="grid place-content-center flex-shrink-0 w-4 h-4">
          <div *ngIf="!testPassword(newPasswordModel.value, '[A-Z]') && (!newPasswordModel.touched || !newPasswordModel.invalid)" class="w-1 h-1 bg-gray-600 rounded-full"></div>
          <svg *ngIf="!testPassword(newPasswordModel.value, '[A-Z]') && newPasswordModel.touched && newPasswordModel.invalid" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.7 1.3C14.3 0.9 13.7 0.9 13.3 1.3L8 6.6L2.7 1.3C2.3 0.9 1.7 0.9 1.3 1.3C0.9 1.7 0.9 2.3 1.3 2.7L6.6 8L1.3 13.3C0.9 13.7 0.9 14.3 1.3 14.7C1.5 14.9 1.7 15 2 15C2.3 15 2.5 14.9 2.7 14.7L8 9.4L13.3 14.7C13.5 14.9 13.8 15 14 15C14.2 15 14.5 14.9 14.7 14.7C15.1 14.3 15.1 13.7 14.7 13.3L9.4 8L14.7 2.7C15.1 2.3 15.1 1.7 14.7 1.3Z"/></svg>
          <svg *ngIf="testPassword(newPasswordModel.value, '[A-Z]')" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.3 2.3L5 11.6L1.7 8.3C1.3 7.9 0.7 7.9 0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7L4.3 13.7C4.5 13.9 4.7 14 5 14C5.3 14 5.5 13.9 5.7 13.7L15.7 3.7C16.1 3.3 16.1 2.7 15.7 2.3C15.3 1.9 14.7 1.9 14.3 2.3Z"/></svg>
        </div>
        <p class="flex-1 text-sm">{{ 'forms.password.uppercase' | translate }}</p>
      </li>
      <li class="flex items-center gap-2" [ngClass]="{ 'text-gray-600': !testPassword(newPasswordModel.value, '[0-9]') && (!newPasswordModel.touched || !newPasswordModel.invalid), 'text-red-600': !testPassword(newPasswordModel.value, '[0-9]') && newPasswordModel.touched && newPasswordModel.invalid, 'text-green-600': testPassword(newPasswordModel.value, '[0-9]') }">
        <div class="grid place-content-center flex-shrink-0 w-4 h-4">
          <div *ngIf="!testPassword(newPasswordModel.value, '[0-9]') && (!newPasswordModel.touched || !newPasswordModel.invalid)" class="w-1 h-1 bg-gray-600 rounded-full"></div>
          <svg *ngIf="!testPassword(newPasswordModel.value, '[0-9]') && newPasswordModel.touched && newPasswordModel.invalid" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.7 1.3C14.3 0.9 13.7 0.9 13.3 1.3L8 6.6L2.7 1.3C2.3 0.9 1.7 0.9 1.3 1.3C0.9 1.7 0.9 2.3 1.3 2.7L6.6 8L1.3 13.3C0.9 13.7 0.9 14.3 1.3 14.7C1.5 14.9 1.7 15 2 15C2.3 15 2.5 14.9 2.7 14.7L8 9.4L13.3 14.7C13.5 14.9 13.8 15 14 15C14.2 15 14.5 14.9 14.7 14.7C15.1 14.3 15.1 13.7 14.7 13.3L9.4 8L14.7 2.7C15.1 2.3 15.1 1.7 14.7 1.3Z"/></svg>
          <svg *ngIf="testPassword(newPasswordModel.value, '[0-9]')" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.3 2.3L5 11.6L1.7 8.3C1.3 7.9 0.7 7.9 0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7L4.3 13.7C4.5 13.9 4.7 14 5 14C5.3 14 5.5 13.9 5.7 13.7L15.7 3.7C16.1 3.3 16.1 2.7 15.7 2.3C15.3 1.9 14.7 1.9 14.3 2.3Z"/></svg>
        </div>
        <p class="flex-1 text-sm">{{ 'forms.password.number' | translate }}</p>
      </li>
      <li class="flex items-center gap-2" [ngClass]="{ 'text-gray-600': !testPassword(newPasswordModel.value, '[^A-Za-z0-9]') && (!newPasswordModel.touched || !newPasswordModel.invalid), 'text-red-600': !testPassword(newPasswordModel.value, '[^A-Za-z0-9]') && newPasswordModel.touched && newPasswordModel.invalid, 'text-green-600': testPassword(newPasswordModel.value, '[^A-Za-z0-9]') }">
        <div class="grid place-content-center flex-shrink-0 w-4 h-4">
          <div *ngIf="!testPassword(newPasswordModel.value, '[^A-Za-z0-9]') && (!newPasswordModel.touched || !newPasswordModel.invalid)" class="w-1 h-1 bg-gray-600 rounded-full"></div>
          <svg *ngIf="!testPassword(newPasswordModel.value, '[^A-Za-z0-9]') && newPasswordModel.touched && newPasswordModel.invalid" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.7 1.3C14.3 0.9 13.7 0.9 13.3 1.3L8 6.6L2.7 1.3C2.3 0.9 1.7 0.9 1.3 1.3C0.9 1.7 0.9 2.3 1.3 2.7L6.6 8L1.3 13.3C0.9 13.7 0.9 14.3 1.3 14.7C1.5 14.9 1.7 15 2 15C2.3 15 2.5 14.9 2.7 14.7L8 9.4L13.3 14.7C13.5 14.9 13.8 15 14 15C14.2 15 14.5 14.9 14.7 14.7C15.1 14.3 15.1 13.7 14.7 13.3L9.4 8L14.7 2.7C15.1 2.3 15.1 1.7 14.7 1.3Z"/></svg>
          <svg *ngIf="testPassword(newPasswordModel.value, '[^A-Za-z0-9]')" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.3 2.3L5 11.6L1.7 8.3C1.3 7.9 0.7 7.9 0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7L4.3 13.7C4.5 13.9 4.7 14 5 14C5.3 14 5.5 13.9 5.7 13.7L15.7 3.7C16.1 3.3 16.1 2.7 15.7 2.3C15.3 1.9 14.7 1.9 14.3 2.3Z"/></svg>
        </div>
        <p class="flex-1 text-sm">{{ 'forms.password.special' | translate }}</p>
      </li>
      <li class="flex items-center gap-2" [ngClass]="{ 'text-gray-600': !testPassword(newPasswordModel.value, '.{8,}') && (!newPasswordModel.touched || !newPasswordModel.invalid), 'text-red-600': !testPassword(newPasswordModel.value, '.{8,}') && newPasswordModel.touched && newPasswordModel.invalid, 'text-green-600': testPassword(newPasswordModel.value, '.{8,}') }">
        <div class="grid place-content-center flex-shrink-0 w-4 h-4">
          <div *ngIf="!testPassword(newPasswordModel.value, '.{8,}') && (!newPasswordModel.touched || !newPasswordModel.invalid)" class="w-1 h-1 bg-gray-600 rounded-full"></div>
          <svg *ngIf="!testPassword(newPasswordModel.value, '.{8,}') && newPasswordModel.touched && newPasswordModel.invalid" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.7 1.3C14.3 0.9 13.7 0.9 13.3 1.3L8 6.6L2.7 1.3C2.3 0.9 1.7 0.9 1.3 1.3C0.9 1.7 0.9 2.3 1.3 2.7L6.6 8L1.3 13.3C0.9 13.7 0.9 14.3 1.3 14.7C1.5 14.9 1.7 15 2 15C2.3 15 2.5 14.9 2.7 14.7L8 9.4L13.3 14.7C13.5 14.9 13.8 15 14 15C14.2 15 14.5 14.9 14.7 14.7C15.1 14.3 15.1 13.7 14.7 13.3L9.4 8L14.7 2.7C15.1 2.3 15.1 1.7 14.7 1.3Z"/></svg>
          <svg *ngIf="testPassword(newPasswordModel.value, '.{8,}')" class="w-4 h-4 fill-current" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14.3 2.3L5 11.6L1.7 8.3C1.3 7.9 0.7 7.9 0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7L4.3 13.7C4.5 13.9 4.7 14 5 14C5.3 14 5.5 13.9 5.7 13.7L15.7 3.7C16.1 3.3 16.1 2.7 15.7 2.3C15.3 1.9 14.7 1.9 14.3 2.3Z"/></svg>
        </div>
        <p class="flex-1 text-sm">{{ 'forms.password.min_length' | translate }}</p>
      </li>
    </ul>
    <div class="mt-5">
      <button class="button button-solid-blue w-full" type="submit"><svg *ngIf="formSending" class="w-4 h-4 mr-2 fill-current animate-spin" xmlns="http://www.w3.org/2000/svg"><path d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM8,2a6,6,0,1,0,6,6A6.006,6.006,0,0,0,8,2Z" opacity="0.4"></path><path d="M16,8H14A6.006,6.006,0,0,0,8,2V0A8.009,8.009,0,0,1,16,8Z"></path></svg><span>{{ 'accounts.password.action' | translate }}</span></button>
    </div>
  </form>
</app-modal>

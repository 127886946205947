// External
import { Observable, Subject } from 'rxjs';

export class Overlay<T> {

  public instance!: T;

  public onPresent: Observable<void>;
  public onDismiss: Observable<any>;

  private onPresentSubject: Subject<void>;
  private onDismissSubject: Subject<any>;

  constructor() {
    this.onPresentSubject = new Subject();
    this.onPresent = this.onPresentSubject.asObservable();

    this.onDismissSubject = new Subject();
    this.onDismiss = this.onDismissSubject.asObservable();
  }

  public present(): void {
    this.onPresentSubject.next();
  }

  public dismiss(data?: any): void {
    this.onDismissSubject.next(data);
  }
}

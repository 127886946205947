// Angular
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent {

  @Input()
  public page!: number;

  @Input()
  public pageCount!: number;

  private readonly maxPagesCount: number;
  private readonly nextPagesCount: number;

  constructor(private router: Router) {
    this.maxPagesCount = 8;
    this.nextPagesCount = 4;
  }

  public get allPages(): Array<number> {
    return Array(this.pageCount).fill(1).map((item, index) => index + 1);
  }

  public get pages(): Array<number> {
    const lastPage = Math.min(this.pageCount, Math.max(this.maxPagesCount, (this.page + this.nextPagesCount)));
    const firstPage = Math.max(1, lastPage - this.maxPagesCount + 1);

    const pages = [];

    for (let i = firstPage; i <= lastPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  public async pageChange(page: number): Promise<void> {
    await this.router.navigate([], { queryParams: { page }, queryParamsHandling: 'merge' });
  }

  public async pagePrevious(): Promise<void> {
    const page = Math.max(1, --this.page);
    await this.pageChange(page);
  }

  public async pageNext(): Promise<void> {
    const page = Math.min(this.pageCount, ++this.page);
    await this.pageChange(page);
  }
}

// Angular
import { Injectable } from '@angular/core';
// Components
import { DialogComponent } from '../components/dialog';
// Models
import { DialogModel, Overlay } from '../models';
// Services
import { OverlayService } from './overlay.service';
// External
import { first } from 'rxjs/operators';

@Injectable()
export class DialogService {

  private overlay: Overlay<DialogComponent> | null;

  constructor(private overlayService: OverlayService) {
    this.overlay = null;
  }

  private addDialog(dialog: DialogModel): void {
    if (!this.overlay) {
      this.overlay = this.overlayService.create(DialogComponent);

      this.overlay.instance.type = dialog.type;
      this.overlay.instance.icon = dialog.icon;
      this.overlay.instance.title = dialog.title;
      this.overlay.instance.description = dialog.description;
      this.overlay.instance.action = dialog.action;

      this.overlay.present();
      this.overlay.onDismiss.pipe(
        first()
      ).subscribe(() => {
        this.overlay = null;
      });
    } else {
      this.overlay.onDismiss.pipe(
        first()
      ).subscribe(() => {
        this.overlay = null;
        this.addDialog(dialog);
      });
    }
  }

  public showSuccess(title: string, description: string, action: string): void {
    this.addDialog({ type: 'success', icon: 'success', title, description, action });
  }

  public showInfo(title: string, description: string, action: string): void {
    this.addDialog({ type: 'info', icon: 'info', title, description, action });
  }

  public showWarning(title: string, description: string, action: string): void {
    this.addDialog({ type: 'warning', icon: 'warning', title, description, action });
  }

  public showDanger(title: string, description: string, action: string): void {
    this.addDialog({ type: 'danger', icon: 'danger', title, description, action });
  }
}

// Angular
import { Directive, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[disableBodyScroll]'
})
export class BodyScrollDirective implements OnInit, OnDestroy {

  private scrollTop: number;

  constructor() {
    this.scrollTop = 0;
  }

  public static disableScroll(scrollTop: number = 0): void {
    // document.body.style.position = 'fixed';
    // document.body.style.top = `-${scrollTop}px`;
    // document.body.style.width = '100%';
    // document.body.style.height = '100%';
    // document.body.style.paddingRight = `${BodyScrollDirective.getScrollbarWidth()}px`;
  }

  public static enableScroll(scrollTop: number = 0): void {
    // document.body.style.removeProperty('position');
    // document.body.style.removeProperty('top');
    // document.body.style.removeProperty('width');
    // document.body.style.removeProperty('height');
    // document.body.style.removeProperty('padding-right');

    // window.scrollTo(0, scrollTop);
  }

  public static getScrollbarWidth(): number {
    // const outer = document.createElement('div');
    // outer.style.visibility = 'hidden';
    // outer.style.overflow = 'scroll';
    // document.body.appendChild(outer);

    // const inner = document.createElement('div');
    // outer.appendChild(inner);

    // const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    // outer.parentNode.removeChild(outer);

    // return scrollbarWidth;
    return 0;
  }

  ngOnInit(): void {
    // this.scrollTop = window.scrollY;
    // BodyScrollDirective.disableScroll(this.scrollTop);
  }

  ngOnDestroy(): void {
    // BodyScrollDirective.enableScroll(this.scrollTop);
  }
}

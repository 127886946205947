// Angular
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[blur]'
})
export class BlurDirective {

  constructor(private elementRef: ElementRef<HTMLElement>) {
  }

  @HostListener('click')
  public clickHandler(): void {
    this.elementRef.nativeElement.blur();
  }
}

// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
// Models
import {
  CreateDeviceModel,
  DeviceActivityModel,
  DeviceCancelReservationActionModel,
  DeviceChangeQRAction,
  DeviceDeinstallationActionModel,
  DeviceInstallationActionModel,
  DeviceInventoryActionModel,
  DeviceModel,
  DeviceRepairActionModel,
  DeviceReplaceActionByUsedDeviceModel,
  DeviceReserveActionModel,
  DeviceScanQRActionModel,
  DevicesFilterModel,
  EditDeviceModel,
  PaginationFilterModel,
  PaginationModel,
  ReplaceDeviceModel
} from '../models';
// External
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class DeviceService {

  public deviceActivityChange: Observable<string | null>;
  private deviceActivityChangeSubject: BehaviorSubject<string | null>;

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
    this.deviceActivityChangeSubject = new BehaviorSubject<string | null>(null);
    this.deviceActivityChange = this.deviceActivityChangeSubject.asObservable();
  }

  public getDevices(filter: DevicesFilterModel): Observable<PaginationModel<DeviceModel>> {
    let params = new HttpParams();

    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          params = params.append(`${key}[]`, item);
        });
      } else {
        params = params.append(key, value);
      }
    });

    return this.httpClient.get<PaginationModel<DeviceModel>>(`${this.apiUrl}/app-admin/v1/devices`, { params });
  }

  public createDevice(data: CreateDeviceModel): Observable<HttpEvent<DeviceModel>> {
    const formData = new FormData();

    Object.entries(data).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public exportDevices(filter: Partial<DevicesFilterModel>): Observable<{ name: string; file: Blob; }> {
    let params = new HttpParams();

    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          params = params.append(`${key}[]`, item);
        });
      } else {
        params = params.append(key, value);
      }
    });

    return this.httpClient.request('GET', `${this.apiUrl}/app-admin/v1/devices/export`, {
      responseType: 'blob',
      observe: 'response',
      params
    }).pipe(
      map(({ headers, body: file }) => {
        const filename = headers.get('content-disposition')!.split('; ').find(item => item.includes('filename'))!;
        const name = filename.replace('filename=', '');

        return {
          name,
          file: file!
        };
      })
    );
  }

  public editDevice(id: string, data: EditDeviceModel): Observable<DeviceModel> {
    const formData = new FormData();

    Object.entries(data).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}`, formData);
  }

  public getDevice(id: string, data: { groups?: Array<string>; } = {}): Observable<DeviceModel> {
    let params = new HttpParams();

    Object.entries(data).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          params = params.append(`${key}[]`, item);
        });
      } else {
        params = params.append(key, value);
      }
    });

    return this.httpClient.get<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}`, { params });
  }

  public findDeviceByQrCode(qrCode: string): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/findByQrCode`, { qrCode });
  }

  public getAllDevicesActivities(filter: PaginationFilterModel): Observable<PaginationModel<DeviceActivityModel>> {
    let params = new HttpParams();

    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          params = params.append(`${key}[]`, item);
        });
      } else {
        params = params.append(key, value);
      }
    });

    return this.httpClient.get<PaginationModel<DeviceActivityModel>>(`${this.apiUrl}/app-admin/v1/deviceActivities`, { params });
  }

  public exportDevicesActivities(q: string, sorting: string, direction: string): Observable<{
    name: string;
    file: Blob;
  }> {
    const params = new HttpParams({ fromObject: { q, sorting, direction } });
    return this.httpClient.request('GET', `${this.apiUrl}/app-admin/v1/deviceActivities/export`, {
      responseType: 'blob',
      observe: 'response',
      params
    }).pipe(
      map(({ headers, body: file }) => {
        const filename = headers.get('content-disposition')!.split('; ').find(item => item.includes('filename'))!;
        const name = filename.replace('filename=', '');

        return {
          name,
          file: file!
        };
      })
    );
  }

  public getDeviceActivity(id: string): Observable<DeviceActivityModel> {
    return this.httpClient.get<DeviceActivityModel>(`${this.apiUrl}/app-admin/v1/deviceActivities/${id}`);
  }

  public confirmDeviceActivity(id: string): Observable<DeviceActivityModel> {
    return this.httpClient.post<DeviceActivityModel>(`${this.apiUrl}/app-admin/v1/deviceActivities/${id}/confirm`, null).pipe(
      tap(({ id }) => {
        this.deviceActivityChangeSubject.next(id);
      })
    );
  }

  public getDeviceActivities(id: string): Observable<Array<DeviceActivityModel>> {
    return this.httpClient.get<Array<DeviceActivityModel>>(`${this.apiUrl}/app-admin/v1/devices/${id}/activities`);
  }

  public deviceInventoryAction(id: string, data: DeviceInventoryActionModel): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/inventory`, data);
  }

  public deviceInstallationAction(id: string, data: DeviceInstallationActionModel): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/installation`, data);
  }

  public deviceDeinstallationAction(id: string, data: DeviceDeinstallationActionModel): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/deinstallation`, data);
  }

  public deviceRepairAction(id: string, data: DeviceRepairActionModel): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/repair`, data);
  }

  public deviceReplaceByNewDevice(id: string, data: ReplaceDeviceModel): Observable<HttpEvent<{
    device: DeviceModel;
    replacingDevice: DeviceModel;
  }>> {
    const formData = new FormData();

    Object.entries(data).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return this.httpClient.post<{
      device: DeviceModel;
      replacingDevice: DeviceModel;
    }>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/replace/newDevice`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public deviceReplaceByUsedDevice(id: string, data: DeviceReplaceActionByUsedDeviceModel): Observable<{
    device: DeviceModel;
    replacingDevice: DeviceModel;
  }> {
    return this.httpClient.post<{
      device: DeviceModel;
      replacingDevice: DeviceModel;
    }>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/replace/usedDevice`, data);
  }

  public deviceScanQrCodeAction(id: string, data: DeviceScanQRActionModel): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/scanQr`, data);
  }

  public deviceChangeQrCodeAction(id: string, data: DeviceChangeQRAction): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/changeQr`, data);
  }

  public deviceReserveAction(id: string, data: DeviceReserveActionModel): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/reserve`, data);
  }

  public deviceCancelReservationAction(id: string, data: DeviceCancelReservationActionModel): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/action/cancelReservation`, data);
  }

  public verifyDevicePhoto(id: string): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/verifyPhoto`, null);
  }

  public archiveDevice(id: string): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/archive`, null);
  }

  public unArchivedDevice(id: string): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/unArchive`, null);
  }

  public editDeviceCustomer(id: string, customer: string): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/customer`, { customer });
  }

  public editDeviceCondition(id: string, condition: string): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/condition`, { condition });
  }

  public editDeviceContractNumber(id: string, contractNumber: string): Observable<DeviceModel> {
    return this.httpClient.post<DeviceModel>(`${this.apiUrl}/app-admin/v1/devices/${id}/contractNumber`, { contractNumber });
  }
}

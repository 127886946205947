// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// Components
import { DatepickerComponent } from './components/datepicker';
import { SelectDateComponent } from './components/select-date';
// Directives
import { ControlStatusDirective } from './directives/control-status';
import { DateFormatDirective } from './directives/date-format';
import { DatepickerInputDirective } from './directives/datepicker-input';
import { EmailValidDirective } from './directives/email-valid';
import { HoursFormatDirective } from './directives/hours-format';
import { PasswordGenerateDirective } from './directives/password-generate';
import { PasswordStrengthDirective } from './directives/password-strength';
import { PostCodeFormatDirective } from './directives/post-code-format';
import { ReplacePatternDirective } from './directives/replace-pattern';
import { SelectPlaceholderDirective } from './directives/select-placeholder';
// External
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  declarations: [
    DatepickerComponent,
    SelectDateComponent,
    ControlStatusDirective,
    DateFormatDirective,
    DatepickerInputDirective,
    EmailValidDirective,
    HoursFormatDirective,
    PasswordGenerateDirective,
    PasswordStrengthDirective,
    PostCodeFormatDirective,
    ReplacePatternDirective,
    SelectPlaceholderDirective
  ],
  exports: [
    DatepickerComponent,
    SelectDateComponent,
    ControlStatusDirective,
    DateFormatDirective,
    DatepickerInputDirective,
    EmailValidDirective,
    HoursFormatDirective,
    PasswordGenerateDirective,
    PasswordStrengthDirective,
    PostCodeFormatDirective,
    ReplacePatternDirective,
    SelectPlaceholderDirective
  ]
})
export class FormModule {

  constructor() {
  }
}

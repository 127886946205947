// Angular
import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[hoursFormat]'
})
export class HoursFormatDirective {

  constructor(private ngControl: NgControl) {
  }

  @HostListener('input', ['$event'])
  public inputHandler(event: InputEvent): void {
    const element = event.target as HTMLInputElement;
    let value = element.value.replace(/[^\d|-]/g, '');
    if (value.length >= 3) {
      value = value.replace(/\D/g, '').replace(/^\d{2}/g, '$&:');
    }

    const selectionStart = element.selectionStart!;
    const selection = event.inputType === 'insertText' && value.charAt(selectionStart - 1) === ':' ? selectionStart + 1 : selectionStart;

    this.ngControl.control!.patchValue(value);

    element.setSelectionRange(selection, selection);
  }
}

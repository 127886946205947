// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// Models
import {
  AccountModel,
  ApiDataModel,
  CreateAccountModel,
  PaginationFilterModel,
  PaginationModel,
} from '../models';
// External
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AccountService {

  public currentAccount: Observable<AccountModel | null>;
  public accountChange: Observable<string | null>;

  private currentAccountSubject: BehaviorSubject<AccountModel | null>;
  private accountChangeSubject: BehaviorSubject<string | null>;

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
    this.currentAccountSubject = new BehaviorSubject<AccountModel | null>(null);
    this.currentAccount = this.currentAccountSubject.asObservable();

    this.accountChangeSubject = new BehaviorSubject<string | null>(null);
    this.accountChange = this.accountChangeSubject.asObservable();
  }

  public setCurrentAccount(account: AccountModel | null): void {
    this.currentAccountSubject.next(account);
  }

  public getCurrentAccount(): AccountModel | null {
    return this.currentAccountSubject.value;
  }

  public createAccount(data: CreateAccountModel): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${this.apiUrl}/app-admin/v1/accounts`, data).pipe(
      tap(({ id }) => {
        this.accountChangeSubject.next(id);
      })
    );
  }

  public getAccounts(filter: PaginationFilterModel): Observable<PaginationModel<AccountModel>> {
    let params = new HttpParams();

    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          params = params.append(`${key}[]`, item);
        });
      } else {
        params = params.append(key, value);
      }
    });

    return this.httpClient.get<PaginationModel<AccountModel>>(`${this.apiUrl}/app-admin/v1/accounts`, { params });
  }

  public exportAccounts(q: string): Observable<{ name: string; file: Blob; }> {
    const params = new HttpParams({ fromObject: { q } });
    return this.httpClient.request('GET', `${this.apiUrl}/app-admin/v1/accounts/export`, {
      responseType: 'blob',
      observe: 'response',
      params
    }).pipe(
      map(({ headers, body: file }) => {
        const filename = headers.get('content-disposition')!.split('; ').find(item => item.includes('filename'))!;
        const name = filename.replace('filename=', '');

        return {
          name,
          file: file!
        };
      })
    );
  }

  public getAccount(id: string): Observable<AccountModel> {
    return this.httpClient.get<AccountModel>(`${this.apiUrl}/app-admin/v1/accounts/${id}`);
  }

  public editAccount(id: string, data: ApiDataModel): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${this.apiUrl}/app-admin/v1/accounts/${id}`, data).pipe(
      tap(() => {
        this.accountChangeSubject.next(id);
      })
    );
  }

  public editAccountBusinessUnits(id: string, businessUnits: Array<number>): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${this.apiUrl}/app-admin/v1/accounts/${id}/businessUnits`, { businessUnits }).pipe(
      tap(() => {
        this.accountChangeSubject.next(id);
      })
    );
  }

  public deleteAccount(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/app-admin/v1/accounts/${id}`).pipe(
      tap(() => {
        this.accountChangeSubject.next(id);
      })
    );
  }

  public changeAccountPassword(id: string, currentPassword: string, newPassword: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-admin/v1/accounts/${id}/password`, {
      currentPassword,
      newPassword
    });
  }

  public requestAccountPasswordReset(email: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-admin/v1/accounts/requestPasswordReset`, { email });
  }

  public resetAccountPassword(token: string, password: string): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-admin/v1/accounts/passwordReset/${token}`, { password });
  }
}

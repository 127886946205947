<app-drawer (close)="closeHandler()">
  <div class="-mt-2 mb-5 -mx-2 text-right">
    <button class="button-icon" aria-label="close" type="button" (click)="closeHandler()"><svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"></path></svg></button>
  </div>
  <p class="font-semibold text-xl text-gray-900">{{ 'device.activity_kind.' + deviceActivity.kind | translate }}</p>
  <p class="mt-3 text-xs text-gray-500">{{ deviceActivity.eventDate | date:'short' }}</p>
  <div *ngIf="!deviceActivity.correctLocation && !deviceActivity.confirmed" class="mt-5">
    <app-status [type]="'danger'" [icon]="'danger'" [heading]="'events.notification.wrong_location.heading' | translate" [message]="'events.notification.wrong_location.message' | translate"></app-status>
  </div>
  <div class="mt-5 space-y-3">
    <div class="p-5 bg-white rounded-lg shadow">
      <p *ngIf="deviceActivity.correctLocation || deviceActivity.confirmed || !accountIsAdmin" class="font-semibold text-base text-gray-900">{{ 'events.details.header' | translate }}</p>
      <p *ngIf="!deviceActivity.correctLocation && !deviceActivity.confirmed && accountIsAdmin" class="flex items-center mb-5"><span class="flex-shrink-0 mr-2 text-red-600"><svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.653455 6.42242C-0.217817 7.29369 -0.217819 8.7063 0.653453 9.57758L6.42242 15.3465C7.29369 16.2178 8.7063 16.2178 9.57758 15.3465L15.3465 9.57758C16.2178 8.70631 16.2178 7.2937 15.3465 6.42242L9.57758 0.653454C8.70631 -0.217817 7.2937 -0.217819 6.42242 0.653453L0.653455 6.42242ZM7.60973 11.9402C7.73469 11.9877 7.86829 12.008 8.0017 12C8.13512 12.008 8.26871 11.9877 8.39367 11.9402C8.51863 11.8928 8.63211 11.8194 8.72662 11.7249C8.82113 11.6304 8.89452 11.5169 8.94193 11.392C8.98935 11.267 9.00972 11.1334 9.0017 11C9.00972 10.8666 8.98935 10.733 8.94193 10.608C8.89452 10.4831 8.82113 10.3696 8.72662 10.2751C8.63211 10.1806 8.51863 10.1072 8.39367 10.0598C8.26871 10.0124 8.13512 9.99198 8.0017 10C7.86829 9.99198 7.73469 10.0124 7.60973 10.0598C7.48477 10.1072 7.37129 10.1806 7.27678 10.2751C7.18228 10.3696 7.10889 10.4831 7.06147 10.608C7.01405 10.733 6.99368 10.8666 7.0017 11C6.99368 11.1334 7.01405 11.267 7.06147 11.392C7.10889 11.5169 7.18228 11.6304 7.27678 11.7249C7.37129 11.8194 7.48477 11.8928 7.60973 11.9402ZM7.0017 9H9.0017V4H7.0017V9Z"></path></svg></span><strong class="font-semibold text-base text-gray-900">{{ 'events.details.header_verify' | translate }}</strong></p>
      <p class="mt-5 text-xs text-gray-500">{{ 'events.account' | translate }}</p>
      <p class="mt-1 text-gray-900">{{ deviceActivity.accountName }}</p>
      <p class="mt-3 text-xs text-gray-500">{{ 'events.device' | translate }}</p>
      <p *ngIf="!!deviceActivity.device" class="mt-1"><a [routerLink]="['/devices', deviceActivity.device.id]" class="block font-semibold text-blue-800 transition-colors hover:text-blue-600" (click)="closeHandler()">{{ deviceActivity.device.name }}</a></p>
      <p class="text-gray-900">S/N: {{ deviceActivity.device?.serialNumber || '-' }}</p>
      <p class="mt-5 text-xs text-gray-500">{{ 'events.client' | translate }}</p>
      <p *ngIf="!deviceActivity.device || !deviceActivity.device.customer" class="mt-1 text-gray-900">{{ 'events.swap_pool' | translate }}</p>
      <p *ngIf="!!deviceActivity.device && !!deviceActivity.device.customer" class="mt-1"><a [routerLink]="['/customers', deviceActivity.device.customer.id]" class="block font-semibold text-blue-800 transition-colors hover:text-blue-600" (click)="closeHandler()">{{ deviceActivity.device.customer.name }}</a></p>
      <p *ngIf="!!deviceActivity.device && !!deviceActivity.device.customer" class="text-gray-900"><span *ngIf="!!deviceActivity.device.customer.street">{{ deviceActivity.device.customer.street }}, </span>{{ deviceActivity.device.customer.city }}</p>
      <p class="mt-3 text-xs text-gray-500">{{ 'events.location' | translate }}</p>
      <p *ngIf="!deviceActivity.lat || !deviceActivity.lng" class="mt-1 text-gray-900">-</p>
      <p *ngIf="!!deviceActivity.lat && !!deviceActivity.lng" class="mt-1">
        <a [href]="googleMapsPlaceUrl" target="_blank" rel="noopener"><img class="w-full h-auto rounded" [src]="googleMapsUrl" role="presentation" alt=""></a>
      </p>
      <div *ngIf="!deviceActivity.correctLocation && !deviceActivity.confirmed && accountIsAdmin" class="mt-5 -mb-5 -mx-5 py-3 px-5 text-right border-t border-gray-100">
        <button blur class="button button-solid-blue" type="button" (click)="showEventVerifyModal()">{{ 'shared.confirm' | translate }}</button>
      </div>
    </div>
  </div>
</app-drawer>

// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Models
import { PaginationMetaModel } from '@data/models';

@Pipe({
  name: 'pagination'
})
export class PaginationPipe implements PipeTransform {

  constructor() {
  }

  public transform(pagination: PaginationMetaModel): string {
    const startIndex = ((pagination.page - 1) * pagination.perPage) + 1;
    const endIndex = Math.min(pagination.page * pagination.perPage, pagination.count);
    return `${startIndex} - ${endIndex}`;
  }
}

// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { FormRepairModel, } from '../models';
// External
import { Observable } from 'rxjs';

@Injectable()
export class FormService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public sendRepairForm(data: FormRepairModel): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/app-admin/v1/form/repair`, data);
  }
}

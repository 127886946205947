// Angular
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// Models
import { CustomerModel, CustomersFilterModel, PaginationModel } from '../models';
// External
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerService {

  constructor(private httpClient: HttpClient, @Inject('API_URL') private apiUrl: string) {
  }

  public getCustomers(filter: CustomersFilterModel): Observable<PaginationModel<CustomerModel>> {
    let params = new HttpParams();

    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          params = params.append(`${key}[]`, item);
        });
      } else if (value === true) {
        params = params.append(key, '1');
      } else if (value === false) {
        params = params.append(key, '0');
      } else {
        params = params.append(key, value);
      }
    });

    return this.httpClient.get<PaginationModel<CustomerModel>>(`${this.apiUrl}/app-admin/v1/customers`, { params });
  }

  public exportCustomers(q: string, sorting: string, direction: string): Observable<{ name: string; file: Blob; }> {
    const params = new HttpParams({ fromObject: { q, sorting, direction } });
    return this.httpClient.request('GET', `${this.apiUrl}/app-admin/v1/customers/export`, {
      responseType: 'blob',
      observe: 'response',
      params
    }).pipe(
      map(({ headers, body: file }) => {
        const filename = headers.get('content-disposition')!.split('; ').find(item => item.includes('filename'))!;
        const name = filename.replace('filename=', '');

        return {
          name,
          file: file!
        };
      })
    );
  }

  public getCustomer(id: string): Observable<CustomerModel> {
    return this.httpClient.get<CustomerModel>(`${this.apiUrl}/app-admin/v1/customers/${id}`);
  }
}

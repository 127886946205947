export function isToday(year: number, month: number, date: number): boolean {
  const today = new Date();
  return today.getFullYear() === year && today.getMonth() === month && today.getDate() === date;
}

export function diffDays(date1: Date, date2: Date = new Date()): number {
  return Math.ceil((date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24);
}

export function diffMonths(date1: Date, date2: Date = new Date()): number {
  return date2.getMonth() - date1.getMonth() + (12 * (date2.getFullYear() - date1.getFullYear()));
}

// Angular
import { Directive, HostBinding } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'select[ngModel], select[formControl], select[formControlName]'
})
export class SelectPlaceholderDirective {

  constructor(private ngControl: NgControl) {
  }

  @HostBinding('class.placeholder')
  public get placeholder(): boolean {
    return !this.ngControl.value && this.ngControl.value !== 0;
  }
}

// Models
import { BusinessUnitModel } from './business-unit.model';

export enum AccountRole {
  ADMIN = 'ROLE_ADMIN',
  BUH = 'ROLE_BUH',
  PS = 'ROLE_PS',
  ST_BAXTER = 'ROLE_ST_BAXTER',
  ST_EXT = 'ROLE_ST_EXT',
  VIEW_ONLY = 'ROLE_VIEW_ONLY'
}

export interface AccountModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: AccountRole;
  businessUnits: Array<BusinessUnitModel>;
  createdAt: string;
  updatedAt: string;
}

export interface CreateAccountModel {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: AccountRole;
}

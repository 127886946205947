// Angular
import { Injectable } from '@angular/core';
// Models
import { BusinessUnitModel } from '@data/models';
// External
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BusinessUnitsService {

  public allBusinessUnits: Array<BusinessUnitModel>;
  public businessUnits: Observable<Array<BusinessUnitModel>>;

  private businessUnitsSubject: BehaviorSubject<Array<BusinessUnitModel>>;

  constructor() {
    this.allBusinessUnits = [];

    this.businessUnitsSubject = new BehaviorSubject<Array<BusinessUnitModel>>([]);
    this.businessUnits = this.businessUnitsSubject.asObservable();
  }

  public setBusinessUnits(businessUnits: Array<BusinessUnitModel>): void {
    this.businessUnitsSubject.next(businessUnits);
  }

  public getBusinessUnits(): Observable<Array<number>> {
    return this.businessUnits.pipe(
      map(businessUnits => businessUnits.map(({ id }) => id))
    );
  }
}

export function isToday(year: number, month: number, date: number): boolean {
  const today = new Date();
  return today.getFullYear() === year && today.getMonth() === month && today.getDate() === date;
}

export function diffDays(date1: Date, date2: Date = new Date()): number {
  return Math.ceil((date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24);
}

export interface CalendarCardDay {
  date: number;
  today: boolean;
  selected: boolean;
  disabled: boolean;
}

export interface CalendarCard<T> {
  year: number;
  month: number;
  days: Array<T>;
  dayNames: Array<string>;
  dayOffset: Array<number>;
}
